.container {
  padding: 20px 26px;
  position: fixed;
  top: 0;
  background-color: #111;
  color: #f3cb27;
  z-index: 100;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.iconText {
  font-size: 22px !important;
  line-height: 25.78px !important;
  font-weight: 700 !important;

  &:hover {
    cursor: pointer;
  }
}

.button {
  float: right;
  width: 200px;
  background-color: #fff;
  padding: 12px 16px;
  text-align: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold !important;
  // font-family: 'Inter', sans-serif;
  font-family: "IBM Plex Mono", monospace;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    padding: 12px;
  }
}

.buttonSupport {
  width: auto;
  background-color: #f3cb27;
}

.buttonIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 8px;
}

.topButtonContainer {
  margin-left: auto !important;
  max-width: 100% !important;
}

.topButtonStack {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    width: 158px;
  }
}

.topButtonSingle {
  display: block !important;
}
