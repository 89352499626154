$color-bg: #f3cb27;
$color-black: #000;
$color-yellow: #ffff00;

// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;700&display=swap");

body {
  background-color: $color-bg;
  // font-family: 'Roboto', sans-serif;
  font-family: "IBM Plex Mono", monospace;
  color: $color-black;
  padding-top: 95px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    padding-top: 105px;
  }
}

button {
  // font-family: 'Inter', sans-serif;
  font-family: "IBM Plex Mono", monospace;
}

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

.font-inter {
  font-family: "Inter", sans-serif !important;
}

.font-ibm-plex-mono {
  font-family: "IBM Plex Mono", monospace;
}

.theme-button {
  background-color: $color-black;
  color: $color-yellow !important;
  text-decoration: none;
  padding: 12px 40px;
  text-align: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-weight: bold;
  font-size: 16px;
  line-height: 19.36px;

  &:hover {
    text-decoration: none;
  }
}
