.topHeader {
  margin-bottom: 44px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    margin-bottom: 20px;
  }
}

.topRow {
  margin-bottom: 40px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    margin-bottom: 16px;
  }
}

.tableRow {
  margin-bottom: 51px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    margin-bottom: 16px;
  }
}

.title {
  font-size: 30px !important;
  line-height: 35px !important;
  margin-top: 20px !important;

  @media screen and (max-width: 624px) {
    font-size: 24px !important;
    line-height: 28px !important;
  }
}
