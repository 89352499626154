.title {
  font-size: 22px !important;
  line-height: 26.63px !important;
}

.subtitle {
  font-size: 12px !important;
  line-height: 14px !important;
}

.forgotLink {
  color: #B6B2B2;
  font-size: 12px;
  line-height: 14px;

  &:hover {
    cursor: pointer;
  }
}

.textMuted {
  font-size: 12px;
  line-height: 14px;
  color: #878585;
  font-style: italic;
}