.card {
  height: 239px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardBody {
  text-align: center;
}

.cardHeading {
  font-size: 50px;
  line-height: 58.59px;
}

.cardSubtitle {
  font-size: 16px;
  line-height: 18.75px;
}
.cardTotalReferralHeading {
  font-size: 40px;
  line-height: 58.59px;
  color: #f3cb27;
}

.cardTotalReferralSubtitle {
  font-size: 24px;
  line-height: 18.75px;
  color: #f3cb27;
}

.button {
  padding: 12px 16px;
  text-align: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold !important;
  // font-family: 'Inter', sans-serif;
  font-family: "IBM Plex Mono", monospace;
  background-color: #f3cb27;
  margin-top: 14px !important;
  cursor: pointer;
}
